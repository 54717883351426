<script>
import * as echarts from 'echarts'
import {setColorForChart} from '@/utils/color'
export default {
    data () {
        return {}
    },
    mounted() {},
    methods:{
        // 温度计
        drawteminCharts(str,data) {
            console.log(data,1123)
            if (data === null ) {
                return
            }
            let temIn = document.getElementById(str)
            let temChart = echarts.init(temIn)
            let TP_value = data * 1
            let kd = []
            let Gradient = []
            let leftColor = ''
            let boxPosition = [60, -40]
            let TP_txt = ''
            // 刻度使用柱状图模拟，短设置1，长的设置3；构造一个数据
            for (var i = 0, len = 135; i <= len; i++) {
                if (i < 10 || i > 130) {
                    kd.push('')
                } else {
                    if ((i - 10) % 20 === 0) {
                        kd.push('-3');
                    } else if ((i - 10) % 4 === 0) {
                        kd.push('-1');
                    } else {
                        kd.push('');
                    }
                }
            }
            TP_txt = '';
            Gradient.push({
                offset: 1,
                color: setColorForChart(TP_value,'tempf')
            })
            leftColor = Gradient[Gradient.length - 1].color
            let option = {
            title: {
                textStyle: {
                color: "#010081",
                align: "center",
                },
                left: "45%",
            },
            grid: { // 控制图的大小，调整下面这些值就可以，
                left: "30%",
                top: "3%",
                bottom: "14%",
            },
            yAxis: [{
                show: false,
                data: [],
                min: 0,
                max: 135,
                axisLine: {
                show: false
                }
            }, {
                show: false,
                min: 0,
                max: 50,
            }, {
                type: 'category',
                data: ['', '', '', '', '', '', '', '', '', ''],
                position: 'left',
                offset: -80,
                axisLabel: { // 单位
                    fontSize: 10,
                    color: 'black'
                },
                axisLine: {
                show: false
                },
                axisTick: {
                show: false
                },
            }],
        
            xAxis: [{
                show: false,
                min: -10,
                max: 80,
                data: []
            }, {
                show: false,
                min: -10,
                max: 80,
                data: []
            }, {
                show: false,
                min: -10,
                max: 80,
                data: []
            }, {
                show: false,
                min: -5,
                max: 80,
        
            }],
            series: [{
                name: '条',
                type: 'bar',
                // 对应上面XAxis的第一个对)象配置
                xAxisIndex: 0,
                data: [{
                value: (TP_value + 30),//这个改那个颜色刻度的
                label: {
                    normal: {
                    show: true,
                    position: boxPosition,
                    width: 20,
                    height: 80,
                    formatter: '{back| ' + TP_value + ' }{unit|°C}\n{downTxt|' + TP_txt + '}',
                    rich: {
                        back: {
                        align: 'center',
                        lineHeight: 50,
                        fontSize: 30,
                        fontFamily: 'digifacewide',
                        color: leftColor
                        },
                        unit: {
                        fontFamily: '微软雅黑',
                        fontSize: 15,
                        lineHeight: 50,
                        color: leftColor
                        },
                        downTxt: {
                        lineHeight: 50,
                        fontSize: 25,
                        align: 'center',
                        color: leftColor
                        }
                    }
                    }
                },
                }],
        
                barWidth: 18,
                itemStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, Gradient)
                }
                },
                z: 2
            }, {
                name: '白框',
                type: 'bar',
                xAxisIndex: 1,
                barGap: '-100%',
                data: [134],
                barWidth: 18,
                itemStyle: {
                normal: {
                    color: '#0C2E6D',
                    barBorderRadius: 50,
                }
                },
                z: 1
            }, {
                name: '外框',
                type: 'bar',
                xAxisIndex: 2,
                barGap: '-100%',
                data: [135],
                barWidth: 28,
                itemStyle: {
                normal: {
                    color: '#4577BA',
                    barBorderRadius: 50,
                }
                },
                z: 0
            }, {
                name: '圆',
                type: 'scatter',
                hoverAnimation: false,
                data: [0],
                xAxisIndex: 0,
                symbolSize: 38,
                itemStyle: {
                normal: {
                    color: setColorForChart(TP_value,'tempf'),
                    opacity: 1,
                }
                },
                z: 2
            }, {
                name: '白圆',
                type: 'scatter',
                hoverAnimation: false,
                data: [0],
                xAxisIndex: 1,
                symbolSize: 50,
                itemStyle: {
                normal: {
                    color: '#0C2E6D',
                    opacity: 1,
                }
                },
                z: 1
            }, {
                name: '外圆',
                type: 'scatter',
                hoverAnimation: false,
                data: [0],
                xAxisIndex: 2,
                symbolSize: 60,
                itemStyle: {
                normal: {
                    color: '#4577BA',
                    opacity: 1,
                }
                },
                z: 0
            }, {
                name: '刻度',
                type: 'bar',
                yAxisIndex: 0,
                xAxisIndex: 3,
                label: {
                normal: {
                    show: true,
                    position: 'left',
                    distance: 15, // 刻度数据位置
                    color: '#000000',// 刻度数字颜色
                    fontSize: 14,
                    formatter: function (params) {
                    if (params.dataIndex > 130 || params.dataIndex < 10) {
                        return '';
                    } else {
                        if ((params.dataIndex - 10) % 20 === 0) {
                        return params.dataIndex - 30;//这个改刻度的，当减70的时候刻度是从-60开始不是从零开始
                        } else {
                        return '';
                        }
                    }
                    }
                }
                },
                barGap: '-100%',
                data: kd,
                barWidth: 1,
                itemStyle: {
                normal: {
                    color: '#000000', // 刻度线颜色
                    barBorderRadius: 120,
                }
                },
                z: 0
            }]
            }
            temChart.setOption(option)

        },
        // 降雨
        drawPre(str,data,step) {
            let dom = document.getElementById(str)
            let chart = echarts.init(dom)
            let option = {
                backgroundStyle: {
                clolor: "#fff",
                },
                series: [
                    {
                    type: "liquidFill",
                    radius: "180px",
                    data: [data / step],
                    label: {
                        normal: {
                        textStyle: {
                            fontSize: 20,
                            fontWeight: "bold",
                        },
                        formatter: data ? data + 'mm' : '0mm'

                        },
                    },
                    color: [
                        {
                        type: "linear",
                        x: 0,
                        y: 1,
                        x2: 0,
                        y2: 0,
                        colorStops: [
                            {
                            offset: 1,
                            color: ["#328CDA"], // 0% 处的颜色
                            },
                            {
                            offset: 0,
                            color: ["#9DC9FF"], // 100% 处的颜色
                            },
                        ],
                        global: false, // 缺省为 false
                        },
                    ],
                    outline: {
                        show: true,
                        borderDistance: 5,
                        itemStyle: {
                        borderColor: "#54B3FC",
                        borderWidth: 3,
                        },
                    },
                    },
                ],
                
            }
            chart.setOption(option)
        },
        // 气压
        drawAt() {
            let dom = document.getElementById('atchart')
            let chart = echarts.init(dom)
            console.log(chart)
        }
    }
}
</script>

<style lang="less" scoped>

</style>