export function statusColor(ty,va) {
	if(ty == "status"){
		if(va == 1){
			return '#00BB00'; //东北
		}
		return '#cccccc';
	}
	if(ty == "tempf"){//温度
		// let wd = ((va - 32)/1.8).toFixed(1)
		let wd = va
		if (wd < -25) {
			return '#7E0579FF';
		} else if (wd <= -24) {
			return '#4C0168FF';
		} else if (wd <= -20) {
			return '#06246DFF';
		} else if (wd <= -16) {
			return '#074BA0FF';
		} else if (wd <= -12) {
			return '#4A6DC7FF';
		} else if (wd <= -8) {
			return '#06A4E1FF';
		} else if (wd <= -4) {
			return '#75DCFBFF';
		} else if (wd <= 0) {
			return '#ABEAF3FF';
		} else if (wd <= 4) {
			return '#C4EDF3FF';
		} else if (wd <= 8) {
			return '#FFFFCDFF';
		} else if (wd <= 12) {
			return '#D4FEB2FF';
		} else if (wd <= 16) {
			return '#B0FD61FF';
		} else if (wd <= 20) {
			return '#FAFF0DFF';
		} else if (wd <= 24) {
			return '#F8C84AFF';
		} else if (wd <= 28) {
			return '#FC8648FF';
		} else if (wd <= 32) {
			return '#F94062FF';
		} else if (wd <= 35) {
			return '#FB0304FF';	
		} else {
			return '#AA0406FF';
		}
	}
	if(ty == "humidity"){//湿度
		let wd = va
		if(wd<=10){
			return '#CC0200';
		} else if(wd <=20){
			return '#FF3333';
		} else if(wd <=30){
			return '#FF6632';
		} else if(wd <=40){
			return '#FF9966';
		} else if(wd <=50){
			return '#FFCC99';
		} else if(wd <=60){
			return '#FEFF99';
		} else if(wd <=70){
			return '#CCFFFE';
		} else if(wd <=70){
			return '#99FEFF';
		} else if(wd <=80){
			return '#009AFE';
		} else if(wd <=95){
			return '#0066FF';
		} else if(wd <=100){
			return '#0332FF';
		} else{
			return '#ffffff';
		}
	}
	if(ty == "windspeedmph"){//风力
		let wd = va
		if(wd<=0.3){
			return '#ffffff';
		} else if(wd <=1.6){
			return '#ffffff';
		} else if(wd <=3.4){
			return '#ffffff';
		} else if(wd <=5.5){
			return '#ffffff';
		} else if(wd <=8.0){
			return '#f7eee7';
		} else if(wd <=10.8){
			return '#fef8bf';
		} else if(wd <=13.9){
			return '#83ef12';
		} else if(wd <=17.2){
			return '#0bc953';
		} else if(wd <=20.8){
			return '#009AFE';
		} else if(wd <=24.5){
			return '#0677a3';
		} else if(wd <=28.5){
			return '#19037c';
		} else if(wd <=32.7){
			return '#54014d';
		} else{
			return '#2b0000';
		}
	}
	if(ty == "winddir"){//风向
		let wd = va
		if(wd > 22.5 && wd <= 67.5){
			return '#83ef12';
		} else if(wd <=112.5){
			return '#83ef12';
		} else if(wd <=157.5){
			return '#83ef12';
		} else if(wd <=202.5){
			return '#83ef12';
		} else if(wd <=247.5){
			return '#83ef12';
		} else if(wd <=292.5){
			return '#83ef12';
		} else if(wd <=337.5){
			return '#83ef12';
		} else if(wd <=22.5 && wd >337.5){
			return '#83ef12';
		} else{
			return '#fff';
		}
	}
	if(ty == "hourlyrainin"){//降水
		let wd = va
		// let wd = (va*25.4).toFixed(2)
		if (wd <= 0.1) {
			return '#ffffff';
		} else if (wd <= 10) {
			return '#cccccc';
		} else if (wd <= 25) {
			return '#3db93a';
		} else if (wd <= 50) {
			return '#5fb7ff';
		} else if (wd <= 100) {
			return '#1601ff';
		} else if (wd <= 250) {
			return '#f614f8';
		} else {
			return '#7e0140';
		}
	}
	if(ty == "solarradiation"){
		let wd = va
		if (wd <= 200) {
			return '#666';
		} else if (wd <= 400) {
			return '#71a0cd';
		} else if (wd <= 600) {
			return '#1f68b5';
		} else if (wd <= 800) {
			return '#017596';
		} else if (wd <= 1000) {
			return '#00b469';
		} else if (wd <= 1200) {
			return '#6fb031';
		} else if (wd <= 1400) {
			return '#b7c939';
		} else if (wd <= 1600) {
			return '#c29d46';
		} else if (wd <= 1800) {
			return '#ba5844';
		} else {
			return '#f72e00';
		}
	}
	if(ty == "uv"){
		let wd = va
		if (wd > -30 && wd <= -15) {
			return '#fff';
		} else if (wd <= 5) {
			return '#1f68b5';
		} else if (wd <= 15) {
			return '#017596';
		} else if (wd <= 30) {
			return '#00b469';
		} else if (wd <= 40) {
			return '#6fb031';
		} else if (wd <= 50) {
			return '#f72e00';
		} else {
			return '#666';
		}
	}
}

import {windDegreeToDir} from './wind'

export function statusValue(ty,va) {
	if(ty == "status"){
		if(va == 1){
			return '在线';
		}
		return '离线';
	}
	if(ty == "tempf"){
		// return ((va - 32)/1.8).toFixed(1)
		return va
	}
	if(ty == "humidity"){
		return va;
	}
	if(ty == "windspeedmph"){
		// return (va * 0.447).toFixed(1);
		return va
	}
	if(ty == "winddir"){
		return va == "" ? "" : windDegreeToDir(va);
	}
	if(ty == "hourlyrainin"){
		// return (va * 25.4).toFixed(2);
		return va
	}
	if(ty == "solarradiation"){
		return va;
	}
	if(ty == "uv"){
		return va;
	}
	if(ty == "baromabsin"){
		// return (va * 33.863882).toFixed(2);
		return va
	}
}

export function setColorForChart(temp) {
	if (temp < 0) {
		return 'rgb(0, 45, 142)'
	} else if (temp > 0 && temp <= 20) {
		return '#32cd32'
	} else if (temp > 20 && temp <= 30) {
		return 'rgb(255, 172, 111)'
	} else if (temp > 30) {
		return '#9d121d'
	}
}